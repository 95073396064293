import React, { useEffect, useState } from "react";
import { Dialog, DialogPanel } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { PhoneIcon, InformationCircleIcon } from "@heroicons/react/20/solid";
import { EnvelopeIcon } from "@heroicons/react/24/outline";

function App() {
  const [data, setData] = useState(null);
  const [sendingContact, setSendingContact] = useState(false);
  const [contacted, setContacted] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phoneNumber: "",
    message: "",
  });

  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          "https://graph.instagram.com/me/media?fields=id,caption,media_type,media_url,permalink,timestamp&access_token=IGAAIkvGeB45lBZAE5QejdPUFE5YUJsaXBUU2NiUUpuSjRsaExRNGxORldHWksxVE5tWEMxakJ4YmEzd0ZAGMlkxS1I1SzF4cWhaUTVuQ012UHVyV3g4LU1GMFFxcnBPaHQ0ZAXZARaEVPTHF6b0F2MWxRWUQxMjBDNDNVYlVFX1JEdwZDZD"
        );
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const result = await response.json();
        setData(result.data.slice(0, 6));
      } catch (error) {
        // setError(error.message);
      } finally {
      }
    };

    fetchData();
  }, []);

  const handleChange = (e) => {
    console.log(e.target);
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  function scrollTo(id) {
    const section = document.querySelector("#" + id);

    setMobileMenuOpen(false);

    if (section) {
      setTimeout(() => {
        section.scrollIntoView({ behavior: "smooth", block: "nearest" });
      }, 200);
    }
  }

  async function submitForm(event) {
    event.preventDefault();

    setSendingContact(true);

    const response = await fetch(
      "https://h33rqaedaw2zrky77knx7shnue0pbqee.lambda-url.eu-west-2.on.aws/post",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      }
    );

    setSendingContact(false);
    setContacted(true);

    setFormData({ name: "", email: "", phoneNumber: "", message: "" }); // Reset form
  }

  return (
    <>
      <div className="h-[90vh] bg-hero-pattern-s lg:bg-hero-pattern bg-cover bg-center relative flex items-center justify-center bg-fixed bg-no-repeat">
        <div className="w-4/5 lg:w-3/4 flex flex-col items-start text-left z-20">
          <h1 className="text-balance text-5xl font-semibold tracking-tight text-white font-brygada xs:-mt-16">
            Welcome to PoundsPooches
          </h1>
          <p className="mt-2 text-pretty text-xl font-medium text-slate-50 sm:text-xl">
            Custom cuts from heads to tails
          </p>
        </div>

        <div className="absolute h-[90vh] w-full bg-slate-900 opacity-50 top-0 z-10"></div>

        <div id="header_container" className="w-full fixed top-0 z-40">
          <div className="bg-primary h-16 w-full m-auto">
            <div className="w-11/12 lg:w-3/4 m-auto flex items-center h-full">
              <p className="text-slate-100 text-left w-full flex items-center gap-1">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="min-h-4 min-w-4 md:w-6 md:h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M10.5 1.5H8.25A2.25 2.25 0 0 0 6 3.75v16.5a2.25 2.25 0 0 0 2.25 2.25h7.5A2.25 2.25 0 0 0 18 20.25V3.75a2.25 2.25 0 0 0-2.25-2.25H13.5m-3 0V3h3V1.5m-3 0h3m-3 18.75h3"
                  />
                </svg>
                07412466007
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  className="min-h-6 min-w-6 md:w-6 md:h-6 ml-2"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M21.75 6.75v10.5a2.25 2.25 0 0 1-2.25 2.25h-15a2.25 2.25 0 0 1-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25m19.5 0v.243a2.25 2.25 0 0 1-1.07 1.916l-7.5 4.615a2.25 2.25 0 0 1-2.36 0L3.32 8.91a2.25 2.25 0 0 1-1.07-1.916V6.75"
                  />
                </svg>
                info@poundspooches.co.uk
              </p>
            </div>
          </div>

          <div className="bg-white h-24 w-full m-auto">
            <div className="w-[90%] lg:w-3/4 m-auto flex items-center h-full justify-between">
              {/* Logo */}
              <div>
                <img src="logo.png" className="h-16 w-32" />
              </div>

              <Dialog
                open={mobileMenuOpen}
                onClose={setMobileMenuOpen}
                className="lg:hidden"
              >
                <div className="fixed inset-0 z-10" />
                <DialogPanel className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10 ">
                  <div className="flex items-center justify-between">
                    <a href="#" className="-m-1.5 p-1.5">
                      <span className="sr-only">Your Company</span>
                      <img alt="" src="logo.png" className="h-16 w-32" />
                    </a>
                    <button
                      type="button"
                      onClick={() => setMobileMenuOpen(false)}
                      className="-m-2.5 rounded-md p-2.5 text-gray-700"
                    >
                      <span className="sr-only">Close menu</span>
                      <XMarkIcon aria-hidden="true" className="size-6" />
                    </button>
                  </div>
                  <div className="mt-6 flow-root">
                    <div className="-my-6 divide-y divide-gray-500/10">
                      <div className="space-y-2 py-6">
                        <a
                          href="#"
                          className="-mx-3 block rounded-lg px-3 py-2 text-base/7 font-semibold text-gray-900 hover:bg-gray-50"
                          onClick={() => scrollTo("about_us")}
                        >
                          About Us
                        </a>
                        <a
                          href="#"
                          className="-mx-3 block rounded-lg px-3 py-2 text-base/7 font-semibold text-gray-900 hover:bg-gray-50"
                          onClick={() => scrollTo("services")}
                        >
                          Services
                        </a>
                        <a
                          href="#"
                          className="-mx-3 block rounded-lg px-3 py-2 text-base/7 font-semibold text-gray-900 hover:bg-gray-50"
                          onClick={() => scrollTo("gallery")}
                        >
                          Gallery
                        </a>
                      </div>
                    </div>
                  </div>
                </DialogPanel>
              </Dialog>

              <div className="flex lg:hidden">
                <button
                  type="button"
                  onClick={() => setMobileMenuOpen(true)}
                  className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
                >
                  <span className="sr-only">Open main menu</span>
                  <Bars3Icon aria-hidden="true" className="size-6" />
                </button>
              </div>

              {/* Inline Text */}
              <div className="hidden gap-2 lg:gap-12 lg:flex">
                <a
                  className="relative cursor-pointer mt-1"
                  onClick={() => scrollTo("about_us")}
                >
                  <p className="text-primary text-lg after:content-[''] after:block after:w-0 after:h-[2px] after:bg-primary after:transition-width after:duration-500 hover:after:w-full">
                    About us
                  </p>
                </a>

                <a
                  className="relative cursor-pointer mt-1"
                  onClick={() => scrollTo("services")}
                >
                  <p className="text-primary text-lg after:content-[''] after:block after:w-0 after:h-[2px] after:bg-primary after:transition-width after:duration-500 hover:after:w-full">
                    Services
                  </p>
                </a>

                <a
                  className="relative cursor-pointer mt-1"
                  onClick={() => scrollTo("gallery")}
                >
                  <p className="text-primary text-lg after:content-[''] after:block after:w-0 after:h-[2px] after:bg-primary after:transition-width after:duration-500 hover:after:w-full">
                    Gallery
                  </p>
                </a>

                <a
                  className="relative cursor-pointer mt-1"
                  onClick={() => scrollTo("contact_us")}
                >
                  <p className="text-primary text-lg after:content-[''] after:block after:w-0 after:h-[2px] after:bg-primary after:transition-width after:duration-500 hover:after:w-full">
                    Contact us
                  </p>
                </a>
              </div>

              <div class="hidden lg:flex lg:gap-3">
                <a
                  href="https://www.facebook.com/people/Pounds-Pooches/61570351520689/"
                  target="_blank"
                  rel="noopener noreferrer"
                  class="text-primary after:content-[''] after:block after:w-0 after:h-[2px] after:bg-primary after:transition-width after:duration-500 hover:after:w-full"
                >
                  <span class="sr-only">Facebook</span>

                  <svg
                    class="size-8"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                    aria-hidden="true"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </a>
                <a
                  href="https://www.instagram.com/poundspooches"
                  target="_blank"
                  rel="noopener noreferrer"
                  class="text-primary after:content-[''] after:block after:w-0 after:h-[2px] after:bg-primary after:transition-width after:duration-500 hover:after:w-full"
                >
                  <span class="sr-only">Instagram</span>
                  <svg
                    class="size-8"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                    aria-hidden="true"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </a>

                <a
                  href="https://www.tiktok.com/@poundspooches"
                  target="_blank"
                  rel="noopener noreferrer"
                  class="text-primary after:content-[''] after:block after:w-0 after:h-[2px] after:bg-primary after:transition-width after:duration-500 hover:after:w-full"
                >
                  <span class="sr-only">YouTube</span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="size-6 my-1"
                    fill="currentColor"
                    viewBox="0 0 16 16"
                  >
                    <path d="M9 0h1.98c.144.715.54 1.617 1.235 2.512C12.895 3.389 13.797 4 15 4v2c-1.753 0-3.07-.814-4-1.829V11a5 5 0 1 1-5-5v2a3 3 0 1 0 3 3z" />
                  </svg>
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className="absolute bottom-0 w-full z-30">
          <svg
            id="visual"
            viewBox="0 0 1440 320"
            className="w-full h-auto"
            xmlns="http://www.w3.org/2000/svg"
            version="1.1"
          >
            <path
              d="M0 275L40 271.2C80 267.3 160 259.7 240 258.7C320 257.7 400 263.3 480 270.3C560 277.3 640 285.7 720 287.8C800 290 880 286 960 284.7C1040 283.3 1120 284.7 1200 284.5C1280 284.3 1360 282.7 1400 281.8L1440 281L1440 321L1400 321C1360 321 1280 321 1200 321C1120 321 1040 321 960 321C880 321 800 321 720 321C640 321 560 321 480 321C400 321 320 321 240 321C160 321 80 321 40 321L0 321Z"
              fill="#d2c1c1"
            />
          </svg>
        </div>
      </div>

      <div className="bg-primary w-full pb-16" id="about_us">
        <div className="w-3/4 m-auto grid grid-cols-1 lg:grid-cols-2 gap-16">
          {/* Text Section */}
          <div className="pt-16 justify-center">
            <h1 className="text-balance text-6xl font-semibold tracking-tight text-white font-brygada">
              Why choose us...
            </h1>
            <p className="mt-4 text-pretty text-xl font-medium text-slate-50 sm:text-xl">
              Pounds Pooches Dog Grooming is a professional home-based salon
              that provides one-on-one dog grooming, ensuring a stress-free
              experience for both pets and their owners. With a focus on quality
              care, Pounds Pooches offers a range of services tailored to meet
              the specific needs of each dog. Pounds Pooches prioritises your
              dog's health and well-being, delivering a high standard of service
              in a calm, dedicated environment. At Pounds Pooches, we believe in
              sanity before vanity, promising to do what is best for your pooch.
            </p>
          </div>

          {/* Image Section */}
          <div className="lg:grid lg:grid-cols-2 lg:grid-cols-2 lg:pt-16 lg:gap-4">
            <div className="aspect-square overflow-hidden rounded-xl  outline outline-1 -outline-offset-1 outline-black/10 shadow-2xl">
              <img
                alt=""
                src="apollo.jpg"
                className="block w-full h-full object-top"
              />
            </div>
            <div className="aspect-square overflow-hidden rounded-xl shadow-2xl outline outline-1 -outline-offset-1 outline-black/10 mt-8 lg:mt-32">
              <img
                alt=""
                src="ned.jpg"
                className="block w-full h-full object-cover"
              />
            </div>
          </div>
        </div>
      </div>

      <div
        className="w-full bg-white min-h-32 pb-1 mb-16 lg:mb-0"
        id="services"
      >
        <div className="w-3/4 m-auto">
          <div className="pt-16 justify-center">
            <h1 className="text-balance text-6xl font-semibold tracking-tight text-primary font-brygada">
              Services
            </h1>
            <p className="mt-4 text-pretty text-xl font-medium text-primary sm:text-xl">
              PLEASE NOTE: Every dog we treat is unique which is why prices may
              vary for each dog.
              <br />
              <br />
              Each groom includes a miniumum of ears and eyes cleaned, nails
              clipped (If able to do safely) and a spray of fragrance.
              <br />
              <br />
              <u>Additional costs</u>
              <br />
              Please DO NOT bring your dog to me if they have fleas. If at the
              time of grooming your dog has fleas they will be bathed in an
              all-natural flea shampoo, which rids of fleas & ticks. This will
              incur an additional <u>£20</u> charge to your groom.
              <br />
              <br />
              If your dog has severe matting an additional <u>£10</u> will be
              charged to your groom due to the extra time, care required, and
              wear and tear to equipment.
            </p>

            <div className="grid grid-cols-2 gap-4 lg:grid-cols-3 xl:gap-4 pt-8">
              <div className="w-full">
                <p className="mt-4 text-pretty text-xl font-medium font-bold tracking-light text-primary sm:text-xl underline">
                  Bath & Clip
                </p>

                <p className="mt-4 text-pretty text-md font-medium text-primary sm:text-xl">
                  Small dog from £35
                  <br />
                  Medium dog from £45
                  <br />
                  Large dog from £55
                  <br />
                  XL dog from £65
                  <br />
                </p>
              </div>

              <div className="w-full">
                <p className="mt-4 text-pretty text-xl font-medium font-bold tracking-light text-primary sm:text-xl underline">
                  Bath & blow dry
                </p>

                <p className="mt-4 text-pretty text-md font-medium text-primary sm:text-xl">
                  Small dog from £25
                  <br />
                  Medium dog from £35
                  <br />
                  Large dog from £45
                  <br />
                  XL dog from £55
                  <br />
                </p>
              </div>

              <div className="w-full">
                <p className="mt-4 text-pretty text-xl font-medium font-bold tracking-light text-primary sm:text-xl underline">
                  Deshed
                </p>

                <p className="mt-4 text-pretty text-md font-medium text-primary sm:text-xl">
                  Small dog from £35
                  <br />
                  Medium dog from £45
                  <br />
                  Large dog from £55
                  <br />
                  XL dog from £65
                  <br />
                </p>
              </div>

              <div className="w-full">
                <p className="mt-4 text-pretty text-xl font-medium font-bold tracking-light text-primary sm:text-xl underline">
                  Hand Stripping
                </p>

                <p className="mt-4 text-pretty text-md font-medium text-primary sm:text-xl">
                  Small dog from £45
                  <br />
                  Medium dog from £55
                  <br />
                  Large dog from £65
                  <br />
                  XL dog from £75
                  <br />
                </p>
              </div>

              <div className="w-full">
                <p className="mt-4 text-pretty text-xl font-medium font-bold tracking-light text-primary sm:text-lg underline">
                  Puppy Intro
                </p>

                <p className="mt-4 text-pretty text-md font-medium text-primary sm:text-xl">
                  From £25
                  <br />
                  <br />
                  I will work with your pup and
                  <br />
                  only do what they allow me to.
                </p>
              </div>

              <div className="w-full">
                <p className="mt-4 text-pretty text-xl font-medium font-bold tracking-light text-primary sm:text-xl underline">
                  Nail Clipping
                </p>

                <p className="mt-4 text-pretty text-md font-medium text-primary sm:text-xl">
                  £10
                  <br />
                  <br />
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="w-full">
        <svg
          id="visual"
          class="w-full h-auto mt-16"
          xmlns="http://www.w3.org/2000/svg"
          version="1.1"
          viewBox="0 248 1440 73"
        >
          <path
            d="M0 248L40 253C80 258 160 268 240 271.5C320 275 400 272 480 267.2C560 262.3 640 255.7 720 257.5C800 259.3 880 269.7 960 275C1040 280.3 1120 280.7 1200 280.3C1280 280 1360 279 1400 278.5L1440 278L1440 321L1400 321C1360 321 1280 321 1200 321C1120 321 1040 321 960 321C880 321 800 321 720 321C640 321 560 321 480 321C400 321 320 321 240 321C160 321 80 321 40 321L0 321Z"
            fill="#d2c1c1"
            stroke-linecap="round"
            stroke-linejoin="miter"
          ></path>
        </svg>
      </div>

      <div className="bg-primary w-full relative min-h-96" id="gallery">
        <div className="w-3/4 m-auto">
          <div className="justify-center">
            <h1 className="text-balance text-6xl font-semibold tracking-tight text-white font-brygada">
              Gallery
            </h1>

            <div class="grid grid-cols-1 md:grid-cols-3 gap-4 mt-8 pb-32">
              {data == null ? (
                <p>nothing</p>
              ) : (
                <>
                  {Object.entries(data).map(([key, media]) => (
                    <div className="relative group" key={key}>
                      <img
                        className="h-auto max-w-full rounded-lg"
                        src={media.media_url}
                        alt=""
                      />

                      <div className="absolute inset-0 bg-black bg-opacity-70 opacity-0 group-hover:opacity-100 transition-opacity duration-300 rounded-lg flex items-center justify-center">
                        <p className="text-white text-lg font-semibold text-center">
                          {media.caption.replace(/#\S+/g, "").trim()}
                        </p>
                      </div>
                    </div>
                  ))}
                </>
              )}
            </div>
          </div>
        </div>

        <svg
          id="visual"
          viewBox="0 0 1440 320"
          className="w-full h-auto absolute bottom-0 z-10"
          xmlns="http://www.w3.org/2000/svg"
          version="1.1"
        >
          <path
            d="M0 251L48 257C96 263 192 275 288 279.5C384 284 480 281 576 278.2C672 275.3 768 272.7 864 268C960 263.3 1056 256.7 1152 254.5C1248 252.3 1344 254.7 1392 255.8L1440 257L1440 321L1392 321C1344 321 1248 321 1152 321C1056 321 960 321 864 321C768 321 672 321 576 321C480 321 384 321 288 321C192 321 96 321 48 321L0 321Z"
            fill="#ffff"
            stroke-linecap="round"
            stroke-linejoin="miter"
          />
        </svg>
      </div>

      <div className="w-full relative" id="contact_us">
        <div className="w-3/4 m-auto">
          <div className="pt-16 justify-center">
            <h1 className="text-balance text-6xl font-semibold tracking-tight text-primary font-brygada">
              Contact us
            </h1>
          </div>

          <div className="relative mx-auto lg:grid lg:grid-cols-5">
            <div className="lg:col-span-2  xl:pr-12">
              <div className="mx-auto max-w-lg">
                <p className="mt-3 text-xl text-primary">
                  Get in touch today to find out more or arrange an appointment.
                </p>
                <dl className="mt-8 text-primary text-xl">
                  <div>
                    <dt className="sr-only">Address</dt>
                    <dd>
                      <p>Station Road, Admaston</p>
                      <p>TF5 0AL</p>
                    </dd>
                  </div>
                  <div className="mt-6">
                    <dt className="sr-only">Phone number</dt>
                    <dd className="flex">
                      <PhoneIcon
                        aria-hidden="true"
                        className="size-6 shrink-0 text-primary"
                      />
                      <span className="ml-3">0741246007</span>
                    </dd>
                  </div>
                  <div className="mt-3">
                    <dt className="sr-only">Email</dt>
                    <dd className="flex">
                      <EnvelopeIcon
                        aria-hidden="true"
                        className="size-6 shrink-0 text-primary"
                      />
                      <span className="ml-3">info@poundspooches.co.uk</span>
                    </dd>
                  </div>
                </dl>
              </div>
            </div>
            <div className="lg:col-span-3 xl:pl-12">
              <div className="mx-auto max-w-lg lg:max-w-none">
                <form
                  action="#"
                  method="POST"
                  className="grid grid-cols-1 gap-y-6"
                  onSubmit={submitForm}
                >
                  <div>
                    <label htmlFor="full-name" className="sr-only">
                      Full name
                    </label>
                    <input
                      id="full-name"
                      name="name"
                      type="text"
                      value={formData.name}
                      onChange={handleChange}
                      placeholder="Full name"
                      autoComplete="name"
                      className="block w-full rounded-md border border-primary px-4 py-3 placeholder-primary shadow-sm focus:border-primary focus:ring-primary text-slate-500"
                    />
                  </div>
                  <div>
                    <label htmlFor="email" className="sr-only">
                      Email
                    </label>
                    <input
                      id="email"
                      name="email"
                      type="email"
                      value={formData.email}
                      onChange={handleChange}
                      placeholder="Email"
                      autoComplete="email"
                      className="block w-full rounded-md border border-primary px-4 py-3 placeholder-primary shadow-sm focus:border-primary focus:ring-primary text-slate-500"
                    />
                  </div>
                  <div>
                    <label htmlFor="phone" className="sr-only">
                      Phone
                    </label>
                    <input
                      id="phone"
                      name="phoneNumber"
                      type="text"
                      value={formData.phoneNumber}
                      onChange={handleChange}
                      placeholder="Phone"
                      autoComplete="tel"
                      className="block w-full rounded-md border border-primary px-4 py-3 placeholder-primary shadow-sm focus:border-primary focus:ring-primary text-slate-500"
                    />
                  </div>
                  <div>
                    <label htmlFor="message" className="sr-only">
                      Message
                    </label>
                    <textarea
                      id="message"
                      name="message"
                      rows={4}
                      value={formData.message}
                      onChange={handleChange}
                      placeholder="Message"
                      className="block w-full rounded-md border border-primary px-4 py-3 placeholder-primary shadow-sm focus:border-primary focus:ring-primary text-slate-500"
                      defaultValue={""}
                    />
                  </div>
                  <div>
                    {!sendingContact ? (
                      !contacted ? (
                        <button
                          type="submit"
                          className="w-full inline-flex justify-center rounded-md border border-1 border-transparent bg-primary px-6 py-3 text-base font-medium text-white shadow-sm hover:bg-white hover:text-primary hover:border-primary hover:border-1 focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2"
                        >
                          Send
                        </button>
                      ) : (
                        <div className="rounded-md bg-blue-50 p-4">
                          <div className="flex">
                            <div className="shrink-0">
                              <InformationCircleIcon
                                aria-hidden="true"
                                className="size-5 text-blue-400"
                              />
                            </div>
                            <div className="ml-3 flex-1 md:flex md:justify-between">
                              <p className="text-sm text-blue-700">
                                Thank you for contacting PoundsPooches, we will be in touch soon 
                              </p>
                              
                            </div>
                          </div>
                        </div>
                      )
                    ) : (
                      <button
                        disabled
                        className="w-full inline-flex justify-center rounded-md border border-1 border-transparent bg-primary px-6 py-3 text-base font-medium text-white shadow-sm hover:bg-white hover:text-primary hover:border-primary hover:border-1 focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2"
                      >
                        Sending...
                      </button>
                    )}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      <footer class="bg-white">
        <div class="mx-auto w-full overflow-hidden px-6 py-16 lg:px-8">
          <div class="mt-16 flex justify-center gap-x-10">
            <a
              href="https://www.facebook.com/people/Pounds-Pooches/61570351520689/"
              target="_blank"
              rel="noopener noreferrer"
              class="text-primary hover:text-gray-800"
            >
              <span class="sr-only">Facebook</span>
              <svg
                class="size-12"
                fill="currentColor"
                viewBox="0 0 24 24"
                aria-hidden="true"
              >
                <path
                  fill-rule="evenodd"
                  d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
                  clip-rule="evenodd"
                />
              </svg>
            </a>
            <a
              href="https://www.instagram.com/poundspooches"
              target="_blank"
              rel="noopener noreferrer"
              class="text-primary hover:text-gray-800"
            >
              <span class="sr-only">Instagram</span>
              <svg
                class="size-12"
                fill="currentColor"
                viewBox="0 0 24 24"
                aria-hidden="true"
              >
                <path
                  fill-rule="evenodd"
                  d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
                  clip-rule="evenodd"
                />
              </svg>
            </a>
            <a
              href="https://www.tiktok.com/@poundspooches"
              target="_blank"
              rel="noopener noreferrer"
              class="text-primary hover:text-gray-800"
            >
              <span class="sr-only">YouTube</span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="size-10 my-1"
                fill="currentColor"
                viewBox="0 0 16 16"
              >
                <path d="M9 0h1.98c.144.715.54 1.617 1.235 2.512C12.895 3.389 13.797 4 15 4v2c-1.753 0-3.07-.814-4-1.829V11a5 5 0 1 1-5-5v2a3 3 0 1 0 3 3z" />
              </svg>
            </a>
          </div>
          <p class="mt-10 text-center text-lg text-primary">
            &copy; 2024 PoundsPooches, Inc. All rights reserved.
          </p>
        </div>
      </footer>
    </>
  );
}

export default App;
